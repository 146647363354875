import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
import { assets } from '../../assets/assets';

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  // Add scroll event listener to change background color
  useEffect(() => {
  
    window.addEventListener('scroll',()=>{
      window.scrollY > 0? setIsScrolled(true) : setIsScrolled(false);
    })

   
  }, []);

  window.addEventListener("scroll", () => {
    const navbar = document.querySelector(".navbar");
    if (window.scrollY > 0) {
      navbar.classList.add("dark-nav");
      navbar.classList.add("scroll-text"); // Add the scroll-text class
    } else {
      navbar.classList.remove("dark-nav");
      navbar.classList.remove("scroll-text"); // Remove the scroll-text class
    }
  });

  const [mobileMenu, setMobileMenu] = useState(false);

  const toggleMenu = ()=>{

    mobileMenu? setMobileMenu(false) : setMobileMenu(true);

  }

  return (
   
    <div className= {`navbar ${isScrolled ? 'dark-nav' : ''}`}   >
       <a href="/" title="Go to Home" aria-label="Navigate to Home">
    <img
      src={assets.logo}
      alt="Company Logo"
      className={`logo ${isScrolled ? 'scrolled-logo' : ''}`}
    />
  </a>
      <ul
  className={`navbar-menu ${isScrolled ? 'scrolled-text' : ''} ${
    mobileMenu ? '' : 'hide-menu'
  }`}
>
        <li>
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/about"
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            About
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/work"
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            Work
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/contact"
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            Contact
          </NavLink>
        </li>
      </ul>
      <div className="navbar-right">
        <ul className={`social ${isScrolled ? 'scrolled-social' : ''}`}>
          <li>
            <a
              href="https://www.linkedin.com/in/khalid-ali-farah/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-linkedin" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a
              href="https://github.com/Khalid-Ali-Farah"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-github" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/khalid.ali.farah/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-instagram" aria-hidden="true"></i>
            </a>
          </li>
        </ul>
        <i class="fa fa-bars" onClick={toggleMenu}></i>
      </div>
    </div>
  );
};

export default Navbar;
