import p_img1 from './p_img1.webp'
import p_img2_1 from './p_img2_1.webp'
import p_img3 from './p_img3.webp'
import p_img4 from './p_img4.webp'
import p_img37 from './p_img37.webp'
import lo from './lo.png'
import IMG_K from './IMG_K.png'



import logo from './logo.png'
import hero_img from './hero_img.png'
import cart_icon from './cart_icon.png'
import bin_icon from './bin_icon.png'
import dropdown_icon from './dropdown_icon.png'
import exchange_icon from './exchange_icon.png'
import profile_icon from './profile_icon.png'
import quality_icon from './quality_icon.png'
import search_icon from './search_icon.png'
import star_dull_icon from './star_dull_icon.png'
import star_icon from './star_icon.png'
import support_img from './support_img.png'
import menu_icon from './menu_icon.png'
import about_img from './about_img.png'
import contact_img from './contact_img.png'
import razorpay_logo from './razorpay_logo.png'
import stripe_logo from './stripe_logo.png'
import cross_icon from './cross_icon.png'
import boss from './boss.png'


export const assets = {
    logo,
    hero_img,
    cart_icon,
    dropdown_icon,
    exchange_icon,
    profile_icon,
    quality_icon,
    search_icon,
    star_dull_icon,
    star_icon,
    bin_icon,
    support_img,
    menu_icon,
    about_img,
    contact_img,
    razorpay_logo,
    stripe_logo,
    cross_icon,
    p_img37,
    p_img3,
    p_img4,
    p_img1,
    p_img2_1,
    lo,
    IMG_K,
    boss,


}

export const products = [
    {
        _id: "id1",
        name: "Demo 1",
        description: "demo web.",
        price: 100,
        image: [p_img1],
        category: "Women",
        subCategory: "Topwear",
        sizes: ["S", "M", "L"],
        date: 1716634345448,
        bestseller: true
    },
    {
        _id: "id2",
        name: "Demo 2",
        description: "Demo 2.",
        price: 200,
        image: [p_img2_1],
        category: "Men",
        subCategory: "Topwear",
        sizes: ["M", "L", "XL"],
        date: 1716621345448,
        bestseller: true
    },
    {
        _id: "id3",
        name: "Demo 3",
        description: "Demo 3",
        price: 220,
        image: [p_img4],
        category: "Kids",
        subCategory: "Topwear",
        sizes: ["S", "L", "XL"],
        date: 1716234545448,
        bestseller: true
    },

    {
        _id: "id4",
        name: "Demo 4",
        description: "Demo 4",
        price: 220,
        image: [p_img3],
        category: "Kids",
        subCategory: "Topwear",
        sizes: ["S", "L", "XL"],
        date: 1716234545448,
        bestseller: true
    },
   

]



export const projects = [
    {
      title: "Asset Borrowing App",
      description: "A mobile app to manage asset borrowing with QR code scanning and availability checks.",
      image: [p_img3], // Replace with actual image
      link: "https://github.com/Khalid-Ali-Farah/asset-borrowing-app", // Replace with actual project link
    },
    {
      title: "Smart Parking System",
      description: "A smart parking system using machine learning to optimize parking management.",
      image: [p_img2_1],
      link: "https://github.com/Khalid-Ali-Farah/smart-parking-system", // Replace with actual project link
    },
    {
      title: "Job Recommender System",
      description: "A recommendation system for job seekers based on their skills and preferences.",
      image: [p_img4],
      link: "https://github.com/Khalid-Ali-Farah/job-recommender-system", // Replace with actual project link
    },
    // Add more projects as needed

    {
        title: "Job Recommender System",
        description: "A recommendation system for job seekers based on their skills and preferences.",
        image: [p_img3],
        link: "https://github.com/Khalid-Ali-Farah/job-recommender-system", // Replace with actual project link
      },

      {
        title: "Job Recommender System",
        description: "A recommendation system for job seekers based on their skills and preferences.",
        image: [p_img3],
        link: "https://github.com/Khalid-Ali-Farah/job-recommender-system", // Replace with actual project link
      },

      {
        title: "Job Recommender System",
        description: "A recommendation system for job seekers based on their skills and preferences.",
        image: [p_img3],
        link: "https://github.com/Khalid-Ali-Farah/job-recommender-system", // Replace with actual project link
      },

 
  ];