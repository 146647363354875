import React from "react";
import "./Work.css"; // Import the CSS file for styling
import { projects } from '../../assets/assets';

const Work = () => {


  return (
    <div className="work-page">
      <h1>My Work</h1>
      <div className="project-grid">
        {projects.map((project, index) => (
          <div className="project-card" key={index}>
            <img src={project.image} alt={project.title} className="project-image" />
            <div className="project-info">
              <h3>{project.title}</h3>
              <p>{project.description}</p>
              <a href={project.link} target="_blank" rel="noopener noreferrer" className="project-link">View Project</a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
