import React from 'react'

import LatestWork from '../components/LatestWork/LatestWork'
import Hero from '../components/Hero/Hero'



const Home = () => {
  return (
    <div>
      <Hero />
      
      <LatestWork />
    </div>
  )
}

export default Home