import React from 'react'
import Navbar from './components/Navbar/Navbar'
import Home from './pages/Home'
import { BrowserRouter, Routes, Route  } from 'react-router-dom'
import About from './pages/About/About'
import Footer from './components/Footer/Footer'
import Work from './pages/Work/Work'
import Contact from './pages/Contact/Contact'
import LatestWork from './components/LatestWork/LatestWork'

const App = () => {
  return (
    <div className=''>
       <BrowserRouter>
       <Navbar />
  
    <Routes>
      
      <Route path='/' element={<Home/>}/>  
      <Route path='/about' element={<About/>}/>  
      <Route path='/work' element={<Work/>}/>  
      <Route path='/contact' element={<Contact/>}/>  
      <Route path='/latest' element={<LatestWork/>}/>  

    </Routes>
    <Footer />
  
    </BrowserRouter>
    </div>
  )
}

export default App