import React from "react";
import "./Footer.css"; // Import the CSS file

const Footer = () => {
  return (
    <footer className="footer">
      {/* First Line: Social Media Icons */}
      <div className="social-media">
      <ul className="social">
          <li>
            <a
              href="https://www.linkedin.com/in/khalid-ali-farah/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-linkedin" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a
              href="https://github.com/Khalid-Ali-Farah"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-github" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/khalid.ali.farah/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-instagram" aria-hidden="true"></i>
            </a>
          </li>
        </ul>
      </div>

      {/* Second Line: Name and Email */}
      <div className="name-email">
        <p>
         
          <a href="mailto:khalid.ali@example.com">khalid.ali.farah.15@gmail.com</a>
        </p>
      </div>

      {/* Footer Bottom: Copyright */}
      <div className="footer-bottom">
        <p>&copy; 2024 Khalid Ali.</p>
      </div>
    </footer>
  );
};

export default Footer;
