import React from 'react';
import { projects } from '../../assets/assets';
import './LatestWork.css';  // Import CSS for styling (optional)

const LatestWork = () => {
  // Sort projects by date (if you want to show the latest projects)
  const latestProjects = projects
    .sort((a, b) => b.date - a.date) // Sort by date in descending order
    .slice(0, 3);  // Get the latest 3 projects

  return (
    <div className="work-container">
     

      {/* Latest Projects Section */}
      <div className="latest-projects">
        <h2>Latest Work</h2>
        <div className="projects-grid">
          {latestProjects.map((project, index) => (
            <div key={index} className="project-card">
              <img
                src={project.image[0]} // Assuming each project has an image
                alt={project.title}
                className="project-image"
              />
              <h3>{project.title}</h3>
              <p>{project.description}</p>
              <a href={project.link} target="_blank" rel="noopener noreferrer">
                View Project
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LatestWork;
