import React from 'react';
import './About.css';
import { assets } from '../../assets/assets';

const About = () => {
  return (
    <>
      <div className="about-container">
        
        <div className='about-me'>
          <div className='left-side'>
            <img src={assets.IMG_K} alt="Khalid Ali Farah" />
          </div>

          <div className='right-side'>
            <p>About Me</p>
            <h1>Software Developer & Web Development Enthusiast Based in Malaysia</h1>
          </div>

        </div>
      </div>

      
      <div className='who-am-i'>
        <p>I'm Khalid Ali Farah, a passionate software developer with a strong focus on web development. I recently graduated with a Bachelor's degree in Software Engineering from Universiti Teknikal Malaysia Melaka (UTeM). My expertise includes building responsive websites, front-end development, and creating intuitive user interfaces using React and Figma.</p>
        <p>During my internship at PSTP, I developed an Asset Borrowing App to streamline the process of borrowing and returning items, incorporating features like QR code scanning and database integration. Additionally, I enhanced the 'Create New Badge' page for the Ucreds platform, showcasing my front-end and UI/UX design skills. As a freelancer, I have worked on various web development projects, further strengthening my skills in React and modern web technologies.</p>
        <p>I enjoy exploring innovative solutions, working in collaborative teams, and continuously learning to advance my skills in software development and UI/UX design. Recently, my interest has shifted more toward web development, which I find highly engaging and impactful.</p>
      </div>
      <div className='what-i-do'>
        <div className='what-left'>
          <img src={assets.boss} alt="Work Illustration" />
        </div>
        <div className='what-rght'>
          <p>My core skills include developing responsive web applications using React, designing intuitive user interfaces with Figma, and implementing scalable front-end solutions using HTML, CSS, JavaScript, and Bootstrap. I am also experienced in back-end development and database management, ensuring end-to-end functionality for my projects.</p>
          <p>Some of my key achievements include creating a Smart Parking System using advanced machine learning and image processing techniques, and leading the development of a Job Recommender System. I am committed to delivering high-quality, impactful solutions tailored to user needs.</p>
          <p>Whether it's creating engaging web applications, enhancing web platforms, or designing user-friendly interfaces, I strive to make technology accessible and functional for everyone.</p>
        </div>
      </div>
    </>
  );
};

export default About;
